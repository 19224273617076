@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.indicators {
  margin-top: 32px;
}

.message {
  opacity: 0;
  z-index: 0;
  height: 0;
  overflow-y: hidden;
  top: 0;

  &[data-active='true'] {
    margin-top: 56px;
    opacity: 1;
    z-index: 1;
    height: auto;
  }
}

.carousel-button-container .carousel-button {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var.$color-background-primary;
  box-shadow: 0 2px 9px -2px var.$color-neutral-75, 0 2px 3px -2px #c0cbd8;

  svg {
    color: #30f;
  }
}

.carousel-button-container button:hover {
  background-color: var.$color-blue-100 !important;
}

/* ========= Variant Standard ============= */
.carousel-wrapper[data-variant='standard'] {
  position: relative;
  width: 100%;
}

[data-variant='standard'] .carousel-item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: left var.$default-animation-timing, transform var.$default-animation-timing,
    opacity var.$default-animation-timing, filter var.$default-animation-timing;
  filter: brightness(0.7);
  z-index: 0;

  &[data-active='true'] {
    z-index: 2;
    opacity: 1;
    filter: brightness(1);
    // Ascend: Shadows + Blurs: https://www.figma.com/file/tn1r1b5fs14dD0kFLSk4mL/Ascend-Design-System?type=design&node-id=278%3A13348&mode=dev

    /* Shadows/Small */
    box-shadow: 0 1px 1px 0 rgb(16 24 40 / 3%), 0 0.5px 3px 0 rgb(16 24 40 / 9%);
  }

  &[data-previous='true'] {
    z-index: 1;
    left: 25%;
    transform: translateX(-50%) scale(0.85);
    opacity: 1;
  }

  &[data-next='true'] {
    z-index: 1;
    left: 75%;
    transform: translateX(-50%) scale(0.85);
    opacity: 1;
  }
}

[data-variant='standard'] .carousel-items {
  width: 100%;
  position: relative;
  max-width: 650px;
}

[data-variant='standard'] .carousel-controls {
  opacity: 0;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  transform: translateY(-50%);
  top: 50%;
  padding: 0 16px;
  z-index: 1;
}

/* ========= Variant Cards ============= */
[data-variant='cards'].carousel-wrapper {
  position: relative;
  width: 100%;
}

[data-variant='cards'] .carousel-item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: left var.$default-animation-timing, transform var.$default-animation-timing,
    opacity var.$default-animation-timing, filter var.$default-animation-timing;
  filter: brightness(0.7);
  z-index: 0;

  &[data-active='true'] {
    z-index: 2;
    opacity: 1;
    filter: brightness(1);
    // Ascend: Shadows + Blurs: https://www.figma.com/file/tn1r1b5fs14dD0kFLSk4mL/Ascend-Design-System?type=design&node-id=278%3A13348&mode=dev

    /* Shadows/Small */
    box-shadow: 0 1px 1px 0 rgb(16 24 40 / 3%), 0 0.5px 3px 0 rgb(16 24 40 / 9%);
  }

  &[data-previous='true'] {
    z-index: 1;
    left: 25%;
    transform: translateX(-50%) scale(0.85);
  }

  &[data-next='true'] {
    z-index: 1;
    left: 75%;
    transform: translateX(-50%) scale(0.85);
  }
}

[data-variant='cards'] .carousel-controls {
  opacity: 0;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  transform: translateY(-50%);
  top: 50%;
  padding: 0 16px;
  z-index: 3;

  button:hover {
    background-color: #fff;
  }
}

/* Medium (large or greater) styles */
@media screen and (min-width: 768px) {
  [data-variant='standard'] .carousel-controls,
  [data-variant='cards'] .carousel-controls {
    opacity: 1;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  [data-variant='standard'] .carousel-item,
  [data-variant='cards'] .carousel-item {
    max-width: initial;
  }

  [data-variant='standard'] .carousel-items {
    max-width: initial;
  }
}
