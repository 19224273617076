@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.divider {
  border-top: 1px solid #c0cbd8;
  border-bottom: none;
  margin: 32px 0;
}

.stat-grid-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 32px;
}

.stat-grid-sub-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.stat-grid-element {
  display: 'flex';
  justify-content: space-around;
  text-align: center;
}

.blue-text {
  color: #30f;
}

.subtitle {
  flex-direction: column;
  max-width: fn.spacing(101);
  margin: auto;
}
